import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Deployed</h1>
        <p>I'm deployed via s3</p>
      </header>
    </div>
  );
}

export default App;
